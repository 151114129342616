@use '../lib';

.overlay {
  order: 4;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 6;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.6s ease-in-out;
  // background: #000000b3;

  &__myPages {
    @include lib.mq($from: 'laptop') {
      display: none !important;
    }
  }

  &__holder {
    position: relative;

    @include lib.mq($from: 'laptop') {
      max-width: 920px;
      /* width */
      &::-webkit-scrollbar {
          width: 10px;
      }
      
      /* Track */
      &::-webkit-scrollbar-track {
          background: transparent; 
      }
      
      /* Handle */
      &::-webkit-scrollbar-thumb {
          background: #888;
          border-radius: 0.625rem;
      }
      
      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
          background: #555; 
      }
   }

  }

  &__filterPopup {
    position: absolute;
    width: 375px;
    height: 100vh;
    right: 0;
    background: #eeebe8;
  }
}

.overlay.overlay--active {
  opacity: 1;
  pointer-events: auto;
  transition: none;
}
.overlay-visible {
  opacity: 1;
}

.SlideModal {
  @include lib.mq($until: 'phone') {
    min-width: auto;
  }
}

.SlideModal__header {
  background: transparent;
}

.SlideModal__footer {
  box-shadow: none;
}

.SlideModal__header {
  box-shadow: none;
}

.js-slideModalHeader {
  padding: 0 1.25rem;
  display: flex;
  justify-content: center;  
  position: relative;
}

.SlideModal__title {
  @include lib.heading5();
  padding-left: 0;
}

.SlideModal__close {
  position: absolute;
  left: 0;
  width: 20px;
  height: 20px;
  display: block;
  cursor: pointer;
  overflow: hidden;
  align-self: flex-start;
}

.SlideModal__close::after {
  content: '';
  background: url('/cdn/images/shared/icon-close.svg') no-repeat;
  width: 18px;
  height: 18px;
  display: block;
}

.SlideModalContent__subSlide {
  display: none;
  position: absolute;
  background: #fde7e7;
  width: 100%;
}

.SlideModalContentHolder {
}

.SlideModalContentItems {
  display: flex;
  justify-content: space-between;
  padding: 0.875rem 0;
  border-bottom: 1px solid rgba(lib.color('text'), 0.1);
  align-items: center;
  color: #fff;
  // cursor: pointer;

  &--picker {
    // cursor: pointer;
    padding: 0.625rem 0;
  }
}

.SlideModalContent__title {
}

.SlideModalContent__menu {
  padding: 0;
  margin: 2rem 0 0;
}

.SlideModalContent__list {
  list-style: none;
  padding-bottom: 0.225rem;  

  &:first-child{
    margin-bottom: 0.625rem;

    .SlideModalContent__item{      
        @include lib.heading10(false, lib.color('secondaryTint1'));
        &:hover{
          color: rgba(lib.color('secondaryTint1'), 0.5);
        }
    }
  }
}

.SlideModalContent__item{
  @include lib.bodyText(false, lib.color('secondaryTint1'));

  &:hover{
    color: rgba(lib.color('secondaryTint1'), 0.5);
  }
}

.SlideModalContent__value {
  display: flex;
  align-items: center;
}

.SlideModalContent__value > label {
  display: flex;
}

.SlideModalContent__count {
  @include lib.bodyMetaMini();
  margin-right: 1.25rem;

  &--selected {
    color: lib.color('primary');
  }
}

.h-overflowHidden {
  overflow: hidden !important;
}

.h-overflowAuto {
  overflow: auto !important;
}
