@use '../lib';
// keep this file for the future updates
.navigationMenu {
  visibility: visible; // temporary added to avoid css warnings
  // &__footer {
  //   padding: 2.5rem 1.25rem;
  //   display: flex;
  //   flex-direction: column;
  //   width: 100%;
  // }

  // &__footerItem {
  //   min-width: 8.75rem;
  //   margin: 0 0.425rem 1rem;

  //   @include lib.mq($from: 'tablet') {
  //     min-width: 10rem;
  //   }
  // }
}
